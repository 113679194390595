import { Component, useState } from 'react';

import { AuthContext, apiRoomMeta } from '../api'
import { RootStackScreenProps } from '../types';

import PresenterRoomScreen from './PresenterRoomScreen';
import AudienceRoomScreen from './AudienceRoomScreen';
import Loading from '../components/Loading';

///

interface RoomScreenProps extends RootStackScreenProps<'Room'> {
  //roomid: string;
}

interface RoomScreenState {
  roommeta: any;
}

export default class RoomScreen extends Component<RoomScreenProps, RoomScreenState> {
  static contextType = AuthContext;
  declare context: React.ContextType<typeof AuthContext>

  // initial state is empty
  state: RoomScreenState = {
    roommeta: null,
  }

  // listener unsubscribe function (cleanup)
  //unsub: any;

  componentDidMount() {
    const { roomid } = this.props.route.params;

    // get room metadata
    apiRoomMeta(roomid).then((roommeta) => {
      // update the state
      this.setState({ roommeta: roommeta });
    });
  }

  componentWillUnmount() {
    // unsubscribe listener from updates
    //this.unsub();

  }

  render() {
    const { roomid } = this.props.route.params;
    const { roommeta } = this.state;

    // if we're still loading, show a message
    if (roommeta === null) return <Loading message={`Loading Room '${roomid}'...`} />;

    // otherwise, figure out what screen to show
    const user = this.context;

    // if we are the owner, show the presenter screen
    if (user.uid == roommeta.owner) return <PresenterRoomScreen {...this.props} roommeta={roommeta} />;

    // otherwise, show the audience screen (default)
    return <AudienceRoomScreen {...this.props} roommeta={roommeta} />
  }
}
