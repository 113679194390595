import { useContext } from "react";
import { TouchableOpacity, StyleSheet } from "react-native";
import { AuthContext, apiSignout } from "../api";
import { Text } from '../components/Themed';


export default function SignoutButton() {
    //const { user, setUser } = useContext(AuthContext);

    
    return (
      <TouchableOpacity style={styles.button} onPress={() => apiSignout()}>
        <Text style={styles.buttonText}>Sign Out</Text>
      </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    button: {
        backgroundColor: '#ff5959',
        padding: 10,
        borderRadius: 4,
    },
    buttonText: {
      fontWeight: 'bold', 
      fontSize: 24 ,
    }
});