import React from 'react';
import { FlatList, StyleSheet } from 'react-native';
import { Text, View } from '../components/Themed';
import RoomListButton from './RoomListButton';
import { RoomID, RoomMeta } from '../api';

interface RoomListProps {
    rooms: { [roomid: RoomID]: RoomMeta },
    title: string,
    join_room(roomid: string): void
}
 
interface RoomListState {
    
}
 
class RoomList extends React.Component<RoomListProps, RoomListState> {
    state = {}
    render() {
      const { rooms } = this.props;
      const items = []
      for (const roomid in rooms) {
        items.push({
          'roomid': roomid,
          'roommeta': rooms[roomid],
        });
      }
        
        return (
      <View>
        <Text style={styles.title1}> {this.props.title} </Text>

        <FlatList data={items} renderItem={({ item }) => (<RoomListButton roomid={item.roomid} roommeta={item.roommeta} onPress={() => this.props.join_room(item.roomid)} />)} keyExtractor={item => item.roomid} />
      </View>
    )
  }
}
 
const styles = StyleSheet.create({
title1: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center'
  },
});

export default RoomList;