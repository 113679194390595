import React from 'react';

const percentage_goal_per_time = 0.9
const time_to_goal = .5
const default_fps = 30

const capture_delay = 2000
const max_stored_points = 20

interface ProportionGraphProps {
    liveData?: Array<Array<number>>
    fps?: number
}
 
interface ProportionGraphState {
    liveTargetData: Array<Array<number>>
    currentData: Array<Array<number>>
    storedGraphData: Array<Array<number>>
    fps: number
    timeOfLastCapture: number
    initialStoredPoints: Array<Array<Array<number>>> // Array of arrays of points
    interpolatedStoredPoints: Array<Array<Array<number>>> // Array of arrays of points
    targetStoredPoints: Array<Array<Array<number>>> // Array of arrays of points
    rate: number
    frame: number
    framesPerSave: number 
    max_x: number
}

const clone = (items) => items.map(item => Array.isArray(item) ? clone(item) : item);

function getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

class ProportionGraph extends React.Component<ProportionGraphProps, ProportionGraphState> {
    changeData: number;
    storeData: number;
    draw: number;

    state = {
        liveTargetData: this.props.liveData ?? [[10.0, 10.0, 10.0]],
        currentData: this.props.liveData ?? [[10.0, 10.0, 10.0]],
        storedGraphData: [[1.0, 1.0, 1.0]],
        fps: this.props.fps ?? default_fps,
        timeOfLastCapture: Date.now(),
        initialStoredPoints: [[[0.0, 33.333], [0.0, 66.666]]],
        interpolatedStoredPoints: [[[0.0, 33.333], [0.0, 66.666]]],
        targetStoredPoints: [[[0.0, 33.333], [0.0, 66.666]]],
        rate: 1 - Math.pow(1-percentage_goal_per_time, 1/((this.props.fps ?? default_fps)*time_to_goal)),
        frame: 1,
        framesPerSave: Math.round(capture_delay/1000*(this.props.fps ?? default_fps)),
        max_x: 100 - 100/max_stored_points
    }

    componentDidUpdate(prevProps: ProportionGraphProps) {
        if (this.props.liveData !== prevProps.liveData) {
          this.setState({ liveTargetData: this.props.liveData });
        }
    }

    // updateClassData = () => {
    //     let arr = clone(this.state.liveTargetData.at(-1));
    
    //     for (let mood = 0; mood < 3; mood++) {
    //         for (let othermood = 0; othermood < 3; othermood++) {
    //             if (mood !== othermood) {
    //                 const current_mood_count = arr[mood];
    //                 const change = getRandomInt(0, Math.floor(current_mood_count/6));
    //                 arr[mood] -= change;
    //                 arr[othermood] += change;
    //             }
    //         }
    //     }
    //     this.setState({liveTargetData: [arr]}); // this will trigger a re-render
    // }

    saveData = () => {
        this.state.storedGraphData.push(clone(this.state.liveTargetData.at(-1)));

        let live = this.state.liveTargetData.at(-1);
        let LTDSum = live.reduce((a, b) => a + b, 0);
        let temp = [[[this.state.max_x, 100*(LTDSum - live[1] - live[2])/LTDSum], [this.state.max_x, 100*(LTDSum - live[2])/LTDSum]]]
        this.state.initialStoredPoints = clone(this.state.targetStoredPoints).concat(temp);
        
        // Crop the data to the max_stored_points most recent points
        this.state.storedGraphData = this.state.storedGraphData.slice(-max_stored_points);
        this.state.initialStoredPoints = this.state.initialStoredPoints.slice(-max_stored_points);
        
        
        let num_points = this.state.storedGraphData.length;
        if(num_points < max_stored_points) {
            // Clear target
            this.state.targetStoredPoints = [];
            let dx = this.state.max_x/num_points;
            for(let i = 0; i < num_points; i++) {
                this.state.targetStoredPoints.push([[i*dx, this.state.initialStoredPoints[i][0][1]], [i*dx, this.state.initialStoredPoints[i][1][1]]]) // TRUST IT BUT DONT TRUST IT!!!
            }
        }
        else {
            this.state.targetStoredPoints = [];
            let dx = this.state.max_x/(max_stored_points-1);
            for(let i = 0; i < max_stored_points; i++) {
                this.state.targetStoredPoints.push([[(i-1)*dx, this.state.initialStoredPoints[i][0][1]], [(i-1)*dx, this.state.initialStoredPoints[i][1][1]]])
            }
        }

        this.setState({timeOfLastCapture: Date.now()}); // this also includes the storedGraphData
    }
    
    componentDidMount() {
        // this.changeData = window.setInterval(() => this.updateClassData(), 300); // increment every 5 seconds
        this.draw = window.setInterval(() => this.moveTowardsTartget(), 1000/this.state.fps);
    }
    
    moveTowardsTartget = () => {
        if(this.state.frame % this.state.framesPerSave === 0) {
            this.saveData();
            this.state.frame = 0;
        }

        let percentThrough = this.state.frame / this.state.framesPerSave;
        
        this.state.interpolatedStoredPoints = []
        for(let i = 0; i < this.state.storedGraphData.length; i++) {
            let intX = (this.state.targetStoredPoints[i][0][0]-this.state.initialStoredPoints[i][0][0])*percentThrough + this.state.initialStoredPoints[i][0][0];
            this.state.interpolatedStoredPoints.push([[intX, this.state.targetStoredPoints[i][0][1]], [intX, this.state.targetStoredPoints[i][1][1]]])
        }

        this.state.currentData[0][0] += (this.state.liveTargetData[0][0] - this.state.currentData[0][0])*this.state.rate
        this.state.currentData[0][1] += (this.state.liveTargetData[0][1] - this.state.currentData[0][1])*this.state.rate
        this.state.currentData[0][2] += (this.state.liveTargetData[0][2] - this.state.currentData[0][2])*this.state.rate

        this.state.frame++;
        this.setState({currentData: this.state.currentData})
    }
    
    componentWillUnmount() {
        clearInterval(this.changeData);
        clearInterval(this.draw);
        clearInterval(this.storeData);
    }

    genCurves = (array) => {

        if(this.state.liveTargetData[0].reduce((a, b) => a + b, 0) === 0) {
            return <rect width={100} height={100} fill='#444444' />
        }

        let curr = clone(this.state.currentData.at(-1));
        let currSum = curr.reduce((a, b) => a + b, 0);
        let currtemp = [[this.state.max_x, 100*(currSum - curr[1] - curr[2])/currSum], [this.state.max_x, 100*(currSum - curr[2])/currSum]]
        let currtemp2 = [[100, 100*(currSum - curr[1] - curr[2])/currSum], [100, 100*(currSum - curr[2])/currSum]]

        let total_arr = clone(this.state.interpolatedStoredPoints).concat([currtemp]).concat([currtemp2]);

        let dYellow = `M 0,100 ${total_arr[0][0][0]},${total_arr[0][0][1]}`;
        let dGreen = `M 0,100 ${total_arr[0][1][0]},${total_arr[0][1][1]}`;
        // Append further points
        let ctrl_x = 0;
        for(let i=1; i < total_arr.length; i++) {
            ctrl_x = (total_arr[i-1][0][0] + total_arr[i][0][0])/2
            dYellow += ` C ${ctrl_x},${total_arr[i-1][0][1]} ${ctrl_x},${total_arr[i][0][1]} ${total_arr[i][0][0]},${total_arr[i][0][1]}`
            dGreen += ` C ${ctrl_x},${total_arr[i-1][1][1]} ${ctrl_x},${total_arr[i][1][1]} ${total_arr[i][1][0]},${total_arr[i][1][1]}`
        }
        // // Append the final point
        dYellow += ' T 100,100';
        dGreen += ' T 100,100';

        return (
            [<path key={0} d={dYellow} fill="#ffff59"/>,
             <path key={1} d={dGreen} fill="#59ff59" />,
            ]
        );
    }

    render() { 
        return (
            <svg viewBox="0 0 100 100" style={{backgroundColor: "#ff5959", flex:1}}  preserveAspectRatio="none">
                {this.genCurves(this.state.liveTargetData)}
            </svg>
        );
    }
}
 
export default ProportionGraph;