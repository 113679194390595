
import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image } from 'react-native';

interface AudienceButtonProps {
  color?: `rgba(${number}, ${number}, ${number}, ${number})`;
  name?: string;
  img?: string;
  onPress?: () => void;
}

interface AudienceButtonState {
}

class AudienceButton extends React.Component<AudienceButtonProps, AudienceButtonState> {
  state = {}
  render() {
    const { onPress } = this.props;
    return (<TouchableOpacity style={[styles.button, { backgroundColor: this.props.color }]} activeOpacity={0.6} onPress={onPress}>
      <Image style={{ flex: 1, resizeMode: 'contain' }} source={this.props.img} />
    </TouchableOpacity>);
  }
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: '#1abc9c', // This is the defalut color of the button
    borderRadius: 20,
    //width: '30%',
    //height: '100%',
    padding: 10,
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  buttonText: {
    color: 'rgb(50,50,50)',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20
  },
});

export default AudienceButton;

