
import { RootStackScreenProps } from '../types';

import { Background, Text, View } from '../components/Themed';
import SiteLogo from '../components/SiteLogo';

import { apiRoomCreate, apiSignout, apiUserGetRoomsOwned, apiUserRoomsJoined, apiUserRoomsOwned, AuthContext, RoomID, RoomMeta } from '../api';
import React from 'react';
import { StyleSheet } from 'react-native';
import JoinRoomButton from '../components/JoinRoomButton';
import SignoutButton from '../components/SignoutButton';
import RoomList from '../components/RoomList';

///

interface HomeScreenProps extends RootStackScreenProps<'Home'> {
}

interface HomeScreenState {
  roomsOwned: { [roomid: RoomID]: RoomMeta };
  roomsJoined: { [roomid: RoomID]: RoomMeta };
}

export default class HomeScreen extends React.Component<HomeScreenProps, HomeScreenState> {
  static contextType = AuthContext;
  declare context: React.ContextType<typeof AuthContext>

  // initial state is empty
  state: HomeScreenState = {
    roomsOwned: {},
    roomsJoined: {},
  }

  // listener unsubscribe function (cleanup)
  unsub1: any;
  unsub2: any;

  componentDidMount() {
    const user = this.context;

    this.unsub1 = apiUserRoomsOwned(user.uid, (rooms) => {
      this.setState({ roomsOwned: rooms });
    });

    this.unsub2 = apiUserRoomsJoined(user.uid, (rooms) => {
      this.setState({ roomsJoined: rooms });
    });
  }

  componentWillUnmount() {
    // unsubscribe listener from updates
    this.unsub1();
    this.unsub2();
  }

  render() {
    const user = this.context;

    const { navigation } = this.props;
    const { roomsOwned, roomsJoined } = this.state;

    // call back for navigating to a given room
    const join_room = (roomid) => { navigation.push('Room', { roomid: roomid }) }
    /*
    apiRoomCreate('BIOL101', {
      'title': 'Biology 101',
      'description': 'Biology class',
      'owner': user.uid,
    }).then((roomid) => {
      console.log('created room', roomid);
    }).catch((err) => {
      console.log('failed to create room', err);
    });
    */
    return (
      <Background>
        <Text style={styles.name}>InterGauge</Text>
        <View style={styles.container}>
          <div style={{height:'30%', display:'flex', flexDirection:'column'}}>
            <SiteLogo />
          </div>
          <Text style={styles.title}>Hey, {user == null ? "Nobody" : user.displayName}! 👋</Text>

          <JoinRoomButton navigation={navigation} />
          <SignoutButton />

          <View style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            gap: 20,
          }}>
            <RoomList title={'Past Rooms'} rooms={roomsJoined} join_room={join_room}></RoomList>
            <RoomList title={'My Rooms'} rooms={roomsOwned} join_room={join_room}></RoomList>
          </View>

        </View>
      </Background>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    paddingTop: '40',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    flex: 1,
    gap: 10,
  },
  name: {
    fontSize: 20,
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#fff',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  listContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    gap: 20,
  },
});


/*


export default function HomeScreen({ navigation }: RootStackScreenProps<'Home'>) {
  const user = useContext(AuthContext);

  // Needs to be hooked into database at some point
  const pastRooms = ['dummyroom']
  const myRooms = ['CS402', 'CadeRoom', 'AlexanderRoom']

  // call back for navigating to a given room
  const join_room = (roomid) => { navigation.push('Room', { roomid: roomid }) }

  return (
    <Background>
      <View style={styles.container}>

        <SiteLogo />
        <Text style={styles.title}>Hey, {user == null ? "Nobody" : user.displayName}! 👋</Text>

        <JoinRoomButton navigation={navigation} />
        <SignoutButton />

        <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.5)" />

        <View style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          gap: 20,
        }}>
          <RoomList title={'Past Rooms'} rooms={pastRooms} join_room={join_room}></RoomList>
          <RoomList title={'My Rooms'} rooms={myRooms} join_room={join_room}></RoomList>
        </View>

      </View>
    </Background>
  )
};
*/
/*


const styles = StyleSheet.create({
  container: {
    paddingTop: 40,
    alignItems: 'center',
    flex: 1,
    gap: 20,

    //flex: 1,
    //alignItems: 'center',
    //textAlign: 'center',
  },
  item: {
    //padding: 10,
    //margin: 10,
  },

  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  input: {
    flex: 1,
    fontSize: 24,
    //height: 50,
    //margin: 12,
    //borderWidth: 1,
    padding: 10,
    backgroundColor: '#fff',
  },
});


const styles_eli = {
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  vert_container: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
    bottomMargin: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  listText: {
    fontSize: 16,
    color: '#000000',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  input: {
    fontSize: 24,
    height: 50,
    margin: 12,
    borderRadius: 4,
    borderWidth: 1,
    padding: 10,
    backgroundColor: '#fff',
  },
  scrollView: {
    backgroundColor: 'black',
    width: '100%',
  },
}


*/

/*

  return (
    <View style={stylexyz.wrapper}>
      <LinearGradient
        colors = {[ '#111111', '#333333','#333333','#111111']}
        style={styles.container}
      >
      <View style={styles.item}>
        <SiteLogo />
      </View>

      <View style={styles.item}>
        <Text style={styles.title}>Hey, {user == null ? "Nobody" : user.displayName}! 👋</Text>
      </View>

      <View style={styles.item}>
        <JoinRoomButton navigation={navigation} />
      </View>

      <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.5)" />

      <View style={styles.item}>
        <SignoutButton />
      </View>

      <View style={{
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
      }}>

        <RoomList title={'Past Rooms'} rooms={pastRooms} join_room={join_room}></RoomList>
        <RoomList title={'My Rooms'} rooms={myRooms} join_room={join_room}></RoomList>

        </View>
    </LinearGradient>
    </View>
  );
}
  */

      //<View style={styles_eli.vert_container}>
