// api.tsx - Firebase API helper for InterGuage

// Firebase API configuration
export const firebaseConfig = {
    apiKey: "AIzaSyD86PMbWuV3T0YwLJLTE_9UP_PKPnkYFIQ",
    authDomain: "intergauge.firebaseapp.com",
    databaseURL: "https://intergauge-default-rtdb.firebaseio.com",
    projectId: "intergauge",
    storageBucket: "intergauge.appspot.com",
    messagingSenderId: "871840589393",
    appId: "1:871840589393:web:0ccd34605547427df4ba70",
    measurementId: "G-HLC6STMDBD"
};

// Firebase API imports
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, User } from "firebase/auth";
import { child, equalTo, get, getDatabase, onChildAdded, onChildChanged, onDisconnect, onValue, orderByChild, ref, serverTimestamp, set, query, onChildRemoved } from "firebase/database";

import { getFunctions, httpsCallable } from "firebase/functions";

// React API imports, 
import { createContext, useEffect, useState } from "react";

//import { getFirestore } from "firebase/firestore";


/// Firebase Handles ///


// create Firebase application, used for everything else
export const firebaseApp = initializeApp(firebaseConfig);

// authentication API handle
export const firebaseAuth = getAuth(firebaseApp)

// realtime database API handle
export const firebaseDatabase = getDatabase(firebaseApp)

// Firebase firestore API
//const firebaseFirestore = getFirestore(firebaseApp)

// Firebase storage API
//const firebaseStorage = firebase.storage(firebaseApp)

// Firebase functions
const firebaseFunctions = getFunctions(firebaseApp)


// user metadata for unknown users
export const unknownUserMeta = {
    uid: null,
    username: 'unknownuser',
    displayname: 'Unknown User',
    bio: 'the requested user does not exist',
    picurl: '/assets/favicon.png',
}

// internal cache of user metadata
let cacheUserMeta: any = {}

// cache timeout in seconds (60*60=1hour)
let cacheUserMetaTimeout = 60 * 60

/// Public API ///

// various auth providers we may want to use
export const firebaseProviders = {
    google: new GoogleAuthProvider()
};



// the type of a user ID
export type UserID = string;

// the type of a user's metadata
export interface UserMeta {
    name: string;
}


// the type of a user input snapshot
export interface UserInput {
    timestamp: number;
    status: string;
    value: number; // -1, 0, +1
}

// the type of a room ID
export type RoomID = string;

// the type of a room metadata snapshot
export interface RoomMeta {
    owner: UserID;
    title: string;
    description: string;
}


// login with the given provider (use 'providers.google' for example) 
export function apiSignin(provider: any) {
    return signInWithPopup(firebaseAuth, provider);
}

// sign out the current user
export function apiSignout() {
    return firebaseAuth.signOut();
}

//export function firebaseFunctions

// join a room, and return a function to leave it
export function apiRoomJoin(roomid: any, callback: (roommeta: any) => void) {
    const userid = firebaseAuth.currentUser!.uid;
    const inputRef = ref(firebaseDatabase, 'rooms/' + roomid + '/inputs/' + userid);

    // input when offline (delete)
    const inputOffline = null;

    // input when online, initially (0)
    const inputOnline = {
        timestamp: serverTimestamp(),
        status: 'online',
        value: 0,
    }

    // listen on connected
    const unsub = onValue(ref(firebaseDatabase, '.info/connected'), (snapshot) => {
        // if we're not connected, don't do anything
        if (snapshot.val() == false) return;

        // if we are connected, then use the 'onDisconnect()'
        // method to add a set which will only trigger once
        onDisconnect(inputRef).set(inputOffline).then(() => {
            // the promise returned from .onDisconnect().set() will
            // resolve as soon as the server acknowledges the onDisconnect()
            // request, NOT once we've actually disconnected:
            // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect
            set(inputRef, inputOnline);
        })
    });

    // return a function to leave the room
    return () => {
        unsub();
        set(inputRef, inputOffline);
    }
}


// submit room input (-1, 0, +1)
export async function apiRoomInput(roomid: string, value: number) {
    console.log(`apiRoomInput(${roomid}, ${value})`);
    const { uid } = firebaseAuth.currentUser!;

    // get a reference to the input node
    const inputRef = ref(firebaseDatabase, 'rooms/' + roomid + '/inputs/' + uid);

    // the input data
    const inputData = {
        timestamp: serverTimestamp(),
        status: 'online',
        value: value,
    }

    // set the user's input
    return await set(inputRef, inputData);
}



// get a user's metadata
export async function apiUserMeta(userid: UserID) {
    // get a reference to the meta node
    const metaRef = ref(firebaseDatabase, 'users/' + userid + '/meta');
    
    // get a snapshot, once
    const snapshot = await get(metaRef);

    let meta = snapshot.val() as UserMeta;

    // ensure defaults
    if (meta.name === undefined) meta.name = 'Unknown User';

    return meta;
}


const ROOMS_ONLY = [
    'SeniorShowcase',
    'CS402',
    'BIO101',
    'ECON201',
    'MATH785',
]

// get a user's rooms that they own
export function apiUserRoomsOwned(userid: UserID, callback: (rooms: { [roomid: RoomID]: RoomMeta }) => void) {

    // we need to order by 'meta.owner' child
    const roomsRef = query(ref(firebaseDatabase, 'rooms'), orderByChild('meta/owner'), equalTo(userid));

    const unsub = onValue(roomsRef, (snapshot) => {
        const data = snapshot.val();
        // extract meta from each
        let result = {}
        for (let roomid in data) {
            // filter
            if (!ROOMS_ONLY.includes(roomid)) continue;
            // add to result
            result[roomid] = data[roomid].meta;
        }

        callback(result);
    });

    // cleanup function
    return () => {
        unsub();
    }
}

// get a user's rooms that they have joined
export function apiUserRoomsJoined(userid: UserID, callback: (rooms: { [roomid: RoomID]: RoomMeta }) => void) {
    // for now, just hardcode
    //const ids = ['COSC402', 'CadeRoom', 'AlexanderRoom', 'EliRoom', 'AbdurhmanRoom', 'KamRoom'];

    // get a reference to the rooms node
    const roomsRef = ref(firebaseDatabase, 'rooms');

    // create a listener
    const unsub = onValue(roomsRef, (snapshot) => {
        const data = snapshot.val();

        // extract meta from each
        let result = {}
        for (let roomid in data) {
            // filter
            if (!ROOMS_ONLY.includes(roomid)) continue;

            // add to result
            result[roomid] = data[roomid].meta;
        }

        callback(result);
    });

    // cleanup function
    return () => {
        unsub();
    }
}
/*
export function apiRoomCreate(roomid: RoomID, roommeta: RoomMeta) {
    // call the 'roomCreate' function
    return httpsCallable(firebaseFunctions, 'roomCreate')({ roomid, roommeta });
    //firebaseFunctions.httpsCallable('roomCreate')({ roomid, roommeta });
}*/

// get the metadata for a room, i.e. the owner, title, description, etc.
export async function apiRoomMeta(roomid: RoomID) {
    // get a reference to the meta node
    const metaRef = ref(firebaseDatabase, 'rooms/' + roomid + '/meta');

    // get a snapshot, once
    const snapshot = await get(metaRef);

    // get data
    const meta = snapshot.val() as RoomMeta;

    return meta;
}

// create a listener for room inputs, calling 'callback()' when a new user input is received (and, for pre-existing user inputs on page load)
// also, when deleted
export function apiRoomOnInputs(roomid: RoomID, callback: (userid: UserID, input: UserInput) => void, callbackdelete: (userid: UserID) => void) {

    // get a reference to the input node
    const inputsRef = ref(firebaseDatabase, 'rooms/' + roomid + '/inputs');

    // called when child is first created (also, called for all existing initial elements in DB when page loads)
    const unsub1 = onChildAdded(inputsRef, (snapshot) => {
        const userid = snapshot.key;
        const input = snapshot.val();
        callback(userid, input);
    });

    // called whenever a child is changed, i.e. when a user has updated their input
    const unsub2 = onChildChanged(inputsRef, (snapshot) => {
        const userid = snapshot.key;
        const input = snapshot.val();
        callback(userid, input);
    });

    // called on delete
    const unsub3 = onChildRemoved(inputsRef, (snapshot) => {
        const userid = snapshot.key;
        callbackdelete(userid);
    });

    // cleanup function
    return () => {
        unsub1();
        unsub2();
        unsub3();
    }
}


// authentication context that holds data about login state
const AuthContext = createContext<User | null>(null)

let apiIsLoading_data = true;

const AuthProvider = ({ children }: any) => {
    // set initial user state, not logged in
    const [user, setUser] = useState<User | null>(null);

    // effect that auto-updates the user when logging in or logging out
    useEffect(() => {
        // create a listener
        const unsub = firebaseAuth.onAuthStateChanged(result => {
            console.log('onAuthStateChanged:', result);
            setUser(result);
            apiIsLoading_data = false;
        });

        // cleanup function
        return unsub
    }, []);

    /*

    // create effect that updates the user's status
    useEffect(() => {
        if (!user) return;

        // logged in, so set status to online
        const { uid } = user;
        const statusRef = ref(firebaseDatabase, 'status/' + uid);

        // create function for the inner listener
        const unsub = onValue(ref(firebaseDatabase, '.info/connected'), (snapshot) => {

            // if we're not connected, don't do anything
            if (snapshot.val() == false) return;

            // if we are connected, then use the 'onDisconnect()'
            // method to add a set which will only trigger once
            // this client has disconnected by closing the app,
            // losing internet, or any other means.
            onDisconnect(statusRef).set(statusOffline).then(() => {
                // the promise returned from .onDisconnect().set() will
                // resolve as soon as the server acknowledges the onDisconnect()
                // request, NOT once we've actually disconnected:
                // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

                // we can now safely set ourselves as 'online' knowing that the
                // server will mark us as offline once we lose connection.
                set(statusRef, statusOnline);
            })
        });
    }, [user]);
    */

    // return a context provider
    return (
        <AuthContext.Provider value={user}>
            {children}
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthProvider };

