import { Pressable, StyleSheet } from 'react-native';
import { Text } from '../components/Themed';

export default function RoomListButton({ roomid, roommeta, onPress, children }: any) {
    return (
      <Pressable style={styles.pressable} key={roomid} onPress={onPress} >
        <Text style={styles.listText}> {roommeta.title} </Text>
        {children}
      </Pressable>
    );
  }

const styles = StyleSheet.create({
    listText: {
        fontSize: 16,
        color: '#000000',
    },
    pressable: {
        marginTop: 10,
        paddingHorizontal: 30,
        paddingVertical: 6,
        width: '100%',
        backgroundColor: '#FFFFFF',
        borderRadius: 4,
        elevation: 3,
        alignItems: 'center',
    }
});