import { StyleSheet } from 'react-native';
import { Text, View } from './Themed';
import SiteLogo from './SiteLogo';
import { Component } from 'react';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '100%',
    },
    item: {
        padding: 10,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    separator: {
        marginVertical: 30,
        height: 1,
        width: '80%',
    },
});

interface LoadingProps {
    message: string;
}

interface LoadingState {
}

export default class Loading extends Component<LoadingProps, LoadingState> {

    state: LoadingState = {
    }

    render() {
        const { message } = this.props;


        return (
            <View style={styles.container}>
                <SiteLogo />
                <Text style={styles.title}>{message}</Text>
            </View>
        )
    }
}
