import { StyleSheet, Image } from 'react-native';

export default function SiteLogo() {
  return <Image
    style={{ width: 300, height: 300 , resizeMode: 'contain', flex: 1}}
    // do our logo
    source={require('../assets/images/logo-1250.png')}
    // source={require('../assets/images/OLD-logo-1024.png')}
    //source={{ uri: user?.photoURL }}
  />
}