import React from 'react';

import { Dimensions, FlatList, Pressable, TouchableOpacity, StyleSheet } from 'react-native';
import { View, Text } from 'react-native';

// import { Text, View } from '../components/Themed';

import { UserID, UserInput, RoomID, apiUserMeta, RoomMeta, apiRoomOnInputs } from '../api'
import { RootStackScreenProps } from '../types';
import ChartSquares from '../components/ChartSquares';
import ProportionGraph from '../components/ProportionGraph';
import SquaresGraph from '../components/SquaresGraph';
import ConvolutionGraph from '../components/ConvolutionGraph';

///

interface PresenterRoomScreenProps extends RootStackScreenProps<'Room'> {
  roommeta: RoomMeta;
  initialGraphType?: string;
}

interface PresenterRoomScreenState {
  roominputs: { [userid: UserID]: UserInput };
  graphType: string;
}

// Create a function to count the number of unique entries in the inputs of a room
function countUsers(roominputs: { [userid: UserID]: UserInput }): number {
  let users: { [userid: UserID]: boolean } = {};
  for (const userid in roominputs) {
      users[userid] = true;
  }
  return Object.keys(users).length;
}

// Create a function that returns an array of the number of users in each value
function countMoods(roominputs: { [userid: UserID]: UserInput }): Array<number> {
  let moods: Array<number> = [0, 0, 0];
  for (const userid in roominputs) {
    const mood = roominputs[userid].value;
    moods[mood+1] += 1;
  }
  return moods;
}

const graphTypes = ['Proportion Graph', 'Squares Graph', 'Histogram Graph']

export default class PresenterRoomScreen extends React.Component<PresenterRoomScreenProps, PresenterRoomScreenState> {
  state: PresenterRoomScreenState = {
    roominputs: {},
    graphType: this.props.initialGraphType || 'Squares Graph',
  }

  // listener callback
  unsub: any;

  componentDidMount() {
    const { roomid } = this.props.route.params;

    // listen for room input updates
    this.unsub = apiRoomOnInputs(roomid, (userid: UserID, input: UserInput) => {
      // update the state
      this.setState((state) => ({
        roominputs: {
          ...state.roominputs,
          [userid]: input,
        }
      }));
    }, (userid: UserID) => {
      // remove the user from the state
      this.setState((state) => {
        const roominputs = { ...state.roominputs };
        delete roominputs[userid];
        return { roominputs };
      });
    });
  }

  componentWillUnmount() {
    // unsubscribe listener
    this.unsub();
  }

  renderGraph() {
    const { roominputs } = this.state;

    if (this.state.graphType == "Proportion Graph") return <ProportionGraph liveData={[countMoods(roominputs)]}/>
    else if (this.state.graphType == "Histogram Graph") return <ConvolutionGraph liveData={[countMoods(roominputs)]}/>
    else if (this.state.graphType == "Squares Graph") return <SquaresGraph liveData={[countMoods(roominputs)]}/>
    else if (this.state.graphType == "??") return <ChartSquares roominputs={roominputs}/>
  }

  render() {
    //const { roominputs, userstatuses } = this.state;


    

      // <View>
      //   <Text>ASDF</Text>

      //   <FlatList
      //     data={Object.entries(roominputs)}
      //     keyExtractor={item => item[0]}
      //     renderItem={({ item }) => (
      //       <Text>TTT: {item[0]}: {item[1].value}</Text>
      //     )}
      //   />

      // </View>

      
    

    return (
      <div style={{backgroundColor: "#0000ff", height:'100%', padding: 0, margin: 0, display: 'flex', flexDirection: 'column', justifyContent:'space-between'}}>
        {this.renderGraph()}

        <div style={{backgroundColor: "#333333", flex: .1, minHeight:50, display: 'flex', justifyContent: 'space-evenly', padding: 10}}>
          {graphTypes.map(item => 
          <TouchableOpacity key={item} style={styles.button} onPress={() => this.setState({graphType: item})}>
            <Text style={{color: "#ffffff"}}>{item}</Text>
          </TouchableOpacity>)}
        </div>
      </div>
    );
  }
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '100%',
  },
  item: {
    padding: 10,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  button: {
    backgroundColor: "#444444",
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20%',
    textAlign: 'center',
  },
});
