import React from 'react';
import { View, Text } from 'react-native';
import { UserID, UserInput, RoomID, apiRoomInputs, apiUserMeta, RoomMeta } from '../api'

const percentage_goal_per_time = 0.9
const time_to_goal = .5
const default_fps = 30

interface ConvolutionGraphProps {
    liveData: Array<Array<number>>
    fps?: number
}
 
interface ConvolutionGraphState {
    liveTargetData: Array<Array<number>>
    currentData: Array<Array<number>>
    width: number
    height: number
    fps: number
    rate: number
}



const clone = (items) => items.map(item => Array.isArray(item) ? clone(item) : item);

function getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Create a function to count the number of unique entries in the inputs of a room
function countUsers(roominputs: { [userid: UserID]: UserInput }): number {
    let users: { [userid: UserID]: boolean } = {};
    for (const userid in roominputs) {
        users[userid] = true;
    }
    return Object.keys(users).length;
}

// Create a function that returns an array of the number of users in each value
function countMoods(roominputs: { [userid: UserID]: UserInput }): Array<number> {
    let moods: Array<number> = [0, 0, 0];
    for (const userid in roominputs) {
        const mood = roominputs[userid].value;
        moods[mood] += 1;
    }
    return moods;
}

class ConvolutionGraph extends React.Component<ConvolutionGraphProps, ConvolutionGraphState> {  
    interval: number;
    draw: number;

    state = {
      liveTargetData: this.props.liveData ?? [[0.0, 0.0, 0.0]],
      currentData: this.props.liveData,
      width: 0,
      height: 0,
      fps: this.props.fps ?? default_fps,
      rate: 1 - Math.pow(1-percentage_goal_per_time, 1/((this.props.fps ?? default_fps)*time_to_goal))
    };

    onLayout = (event: any) => {
        const { width, height } = event.nativeEvent.layout;
        this.setState({ width, height });
    };

    componentDidUpdate(prevProps: ConvolutionGraphProps) {
        if (this.props.liveData !== prevProps.liveData) {
          this.setState({ liveTargetData: this.props.liveData });
        }
    }

    moveTowardsTartget = () => {
        let temp = clone(this.state.currentData)[0]
        temp[0] += (this.state.liveTargetData[0][0] - temp[0])*this.state.rate
        temp[1] += (this.state.liveTargetData[0][1] - temp[1])*this.state.rate
        temp[2] += (this.state.liveTargetData[0][2] - temp[2])*this.state.rate

        this.setState({currentData: [temp]})
    }
      
    componentDidMount() {
        this.draw = window.setInterval(() => this.moveTowardsTartget(), 1000/this.state.fps);
    }
    
    componentWillUnmount() {
        clearInterval(this.interval);
        clearInterval(this.draw);
    }

    
    genHist = (array) => {
        if(this.state.liveTargetData[0].reduce((a, b) => a + b, 0) === 0) {
            return <rect width={100} height={100} fill='#444444' />
        }

        array = [...array.slice(-1)]
        let arr = clone(array.slice(-1))[0]

        let sum = arr[0] + arr[1] + arr[2]
        arr[0] /= sum
        arr[1] /= sum
        arr[2] /= sum

        const num_points = 200

        let points = []
        let y = 0
        const b = 1.01
        const d = [15, 50, 85]
        for(let i = num_points; i >= 0; i--) {
            y = this.state.height*(1 - arr[0]*Math.pow(b, -1*(((i/num_points*100)-d[0])**2)) - arr[1]*Math.pow(b, -1*(((i/num_points*100)-d[1])**2)) - arr[2]*Math.pow(b, -1*(((i/num_points*100)-d[2])**2)))
            points.push([i/num_points*this.state.width, y])
        }

        let path = `M 0 ${this.state.height} ${this.state.width} ${this.state.height}`
        for(let p of points) {
            path += ` ${  p[0]} ${p[1]}`
        }

        let horizontal_seperators = []
        for(let i = 0; i <= sum; i+=10)
            horizontal_seperators.push(i)

        return ([
            horizontal_seperators.map(item => [<line key={item} x1={60} x2={this.state.width} y1={(1-item/sum)*this.state.height} y2={(1-item/sum)*this.state.height} stroke='#333333' strokeWidth={2}/>, 
                                               <Text key={item+1} fill="white" fontSize="20" x="20" y={(1-item/sum)*this.state.height+6.5} >{item}</Text>]),
            <path key={0} className="s0" d={path}/>,
        ])
    }
    
    render() {
        return (
            <View onLayout={this.onLayout} style={{flex:1}}>
            <svg viewBox={`0 0 ${this.state.width} ${this.state.height}`} style={{backgroundColor: "#444444", flex:1}}  preserveAspectRatio="none">
            <defs>
                <linearGradient id="g1" x1={0} x2={this.state.width} gradientUnits="userSpaceOnUse">
                    <stop offset={0.1} stopColor="#ff5959" />
                    <stop offset={0.5} stopColor="#ffff59" />
                    <stop offset={0.9} stopColor="#59ff59" />
                </linearGradient>
            </defs>
                <style>{"\n\t\t.s0 { fill: url(#g1) } \n\t\t.s1 { fill: #444444 } \n\t"}</style>,
                {this.genHist(this.state.currentData)}
            </svg>
            </View>
        );
    }
}
 
export default ConvolutionGraph;