
import { RootStackScreenProps } from '../types';

import { Background, Text, View } from '../components/Themed';
import SiteLogo from '../components/SiteLogo';

import { apiInput, apiSignout, apiRoomInput, AuthContext, RoomMeta, apiRoomJoin } from '../api';
import React from 'react';
import { StyleSheet } from 'react-native';
import JoinRoomButton from '../components/JoinRoomButton';
import SignoutButton from '../components/SignoutButton';
import RoomList from '../components/RoomList';
import AudienceButton from '../components/AudienceButton';

///

interface AudienceRoomScreenProps extends RootStackScreenProps<'Room'> {
  //roomid: string;
  roommeta: RoomMeta | null;

}

interface AudienceRoomScreenState {
  buttonsDirection: any;
}


const stylesAlexander = StyleSheet.create({
  Container: {
    display: 'flex',
    flex: 1,
    alignItems: 'stretch',
    width: '100%'
  },
  undertandingContainer: {
    display: 'flex',
    padding: 30,
    flex: 1,
    height: '100%',
    width: '100%',
    justifyContent: 'space-between',
    //flexDirection: 'row',
    // height: '90%',
  },
});


export default class AudienceRoomScreen extends React.Component<AudienceRoomScreenProps, AudienceRoomScreenState> {
  static contextType = AuthContext;
  declare context: React.ContextType<typeof AuthContext>

  // initial state is empty
  state: AudienceRoomScreenState = {
    //roommeta: null,
    buttonsDirection: 'column',
  }

  //state: AudienceRoomScreenState = { roominfo: null, roomstate: null }

  // listener unsubscribe function (cleanup)
  unsub: any;

  calculateDimensions = ({ nativeEvent }) => {
    const { layout } = nativeEvent;

    if (layout.width > 768) {
      this.setState({ buttonsDirection: 'row' });
    } else {
      this.setState({ buttonsDirection: 'column' });
    }
  };
  componentDidMount() {
    const { roomid } = this.props.route.params;

    // create a listener for the room
    this.unsub = apiRoomJoin(roomid, (result) => {
      //this.setState({ roommeta: result });
    });
  }

  componentWillUnmount() {
    // unsubscribe listener from updates
    this.unsub();
  }

  render() {
    const { roomid } = this.props.route.params;

    return (
      <Background>
        <View style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center' }}>
          <View style={stylesAlexander.Container}>
            <View onLayout={this.calculateDimensions} style={{...stylesAlexander.undertandingContainer, flexDirection: this.state.buttonsDirection}}>
              <AudienceButton onPress={() => apiRoomInput(roomid, -1)} color='rgba(255, 89, 89, 1.00)' name="Don't Understand" img={require('../assets/images/dontUnderstand.svg')} />
              <AudienceButton onPress={() => apiRoomInput(roomid, 0)} color='rgba(255, 255, 89, 1.00)' name='Confused' img={require('../assets/images/confused.svg')} />
              <AudienceButton onPress={() => apiRoomInput(roomid, +1)} color='rgba(89, 255, 89, 1.00)' name='Understand' img={require('../assets/images/doUnderstand.svg')} />
            </View>
          </View>
        </View>
      </Background >
    )
  }
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 40,
    alignItems: 'center',
    flex: 1,
    gap: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  listContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    gap: 20,
  },
});

