import React from 'react';

import { Background, Text, View } from '../components/Themed';

import { UserID, UserInput } from '../api';
import { LineChart } from 'react-native-chart-kit';
import { Dimensions } from 'react-native';

interface ChartSquaresProps {
  roominputs: { [userid: UserID]: UserInput };
}

interface ChartSquaresState {
  //width: number
  //height: number

  /*
  graphData: Array<Array<number>>
  */
}


function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default class ChartSquares extends React.Component<ChartSquaresProps, ChartSquaresState> {

  state: ChartSquaresState = {
    //graphData: [[20.0, 10.0, 1.0]],
    //width: 0,

    //height: 0,
  };
  //interval: number;

  /*

  onLayout = (event: any) => {
    const { width, height } = event.nativeEvent.layout;
    this.setState({ width, height });
  };

  
  updateClassData = () => {
    let arr = [...this.state.graphData.at(-1)];

    for (let mood = 0; mood < 3; mood++) {
      for (let othermood = 0; othermood < 3; othermood++) {
        if (mood !== othermood) {
          // mood and other mood are different
          const current_mood_count = arr[mood];
          const change = getRandomInt(0, Math.floor(current_mood_count / 3));
          arr[mood] -= change;
          arr[othermood] += change;
        }
      }
    }
    this.state.graphData.push(arr);
    this.setState((prevState) => ({ graphData: prevState.graphData })); // this will trigger a re-render
  }

  componentDidMount() {
    this.interval = window.setInterval(() => this.updateClassData(), 1000); // increment every 5 seconds
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }*/

  /*

  genSquares = (array) => {
    array = [...array.slice(-1)]
    let arr = clone(array.slice(-1))[0]

    arr[1] += arr[2]
    arr[0] += arr[1]

    let n = arr[0]

    let height = this.state.height
    let width = this.state.width

    let x_dim = Math.sqrt(n * (this.state.width / this.state.height))
    let y_dim = x_dim * (this.state.height / this.state.width)
    // console.log("Freedom",this.state.width)
    let x_count = 100000
    let y_count = 100000

    if (height / width >= n) {
      y_count = n
      x_count = 1
    }
    else if (width / height >= n) {
      x_count = n
      y_count = 1
    }

    // Find the best ratio
    if (Math.floor(x_dim) * Math.ceil(y_dim) > n && Math.floor(x_dim) * Math.ceil(y_dim) < x_count * y_count) {
      x_count = Math.floor(x_dim)
      y_count = Math.ceil(y_dim)
    }
    if (Math.ceil(x_dim) * Math.floor(y_dim) > n && Math.ceil(x_dim) * Math.floor(y_dim) < x_count * y_count) {
      x_count = Math.ceil(x_dim)
      y_count = Math.floor(y_dim)
    }
    if (Math.ceil(x_dim) * Math.ceil(y_dim) > n && Math.ceil(x_dim) * Math.ceil(y_dim) < x_count * y_count) {
      x_count = Math.ceil(x_dim)
      y_count = Math.ceil(y_dim)
    }

    if (x_count >= 100000)
      return <line x1="0" y1="80" x2="100" y2="20" stroke="black" />

    // console.log(x_count)
    let vertical_seperators = []
    for (let i = 0; i <= x_count; i++)
      vertical_seperators.push(width * i / x_count)
    let horizontal_seperators = []
    for (let i = 0; i <= y_count; i++)
      horizontal_seperators.push(height * i / y_count)
    // console.log(vertical_seperators)


    let coords = []
    for (let i = 0; i < 3; i++) {
      let coord = [Math.floor(arr[i] / x_count)]
      coord.push(arr[i] - (coord[0] * x_count))
      coord.push(Math.ceil(coord[1] / x_count) + coord[0])
      coords.push(coord)
    }


    return ([
      <rect width={width} height={height * (coords[0][0] / y_count)} fill='#ff5959' />,
      <rect width={width * (coords[0][1] / x_count)} height={height * (coords[0][2] / y_count)} fill='#ff5959' />,
      <rect width={width} height={height * (coords[1][0] / y_count)} fill='#ffff59' />,
      <rect width={width * (coords[1][1] / x_count)} height={height * (coords[1][2] / y_count)} fill='#ffff59' />,
      <rect width={width} height={height * (coords[2][0] / y_count)} fill='#59ff59' />,
      <rect width={width * (coords[2][1] / x_count)} height={height * (coords[2][2] / y_count)} fill='#59ff59' />,
      vertical_seperators.map(item => <line x1={item} x2={item} y1={0} y2={height} stroke='black' stroke-opacity="0.1" stroke-width='1%' />),
      horizontal_seperators.map(item => <line x1={0} x2={width} y1={item} y2={item} stroke='black' stroke-opacity="0.1" stroke-width='1%' />)
    ])
  }

      <View onLayout={this.onLayout} style={{ flex: 1 }}>
        <svg viewBox={"0 0 " + this.state.width.toString() + ' ' + this.state.height.toString()} style={{ backgroundColor: "#444444", flex: 1 }} preserveAspectRatio="none">
          {this.genSquares(this.state.graphData)}
        </svg>
      </View>
*/
  render() {
    return (
      <Background>
<View>
  <Text>Bezier Line Chart</Text>
  <LineChart
    data={{
      labels: ["Confused", "Neutral", "Following"],
      datasets: [
        {
          data: [
            Math.random() * 100,
            Math.random() * 100,
            Math.random() * 100,
          ]
        }
      ]
    }}
    width={Dimensions.get("window").width} // from react-native
    height={220}
    yAxisLabel="$"
    yAxisSuffix="k"
    yAxisInterval={1} // optional, defaults to 1
    chartConfig={{
      backgroundColor: "#e26a00",
      backgroundGradientFrom: "#fb8c00",
      backgroundGradientTo: "#ffa726",
      decimalPlaces: 2, // optional, defaults to 2dp
      color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
      labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
      style: {
        borderRadius: 16
      },
      propsForDots: {
        r: "6",
        strokeWidth: "2",
        stroke: "#ffa726"
      }
    }}
    bezier
    style={{
      marginVertical: 8,
      borderRadius: 16
    }}
  />
</View>
      </Background>
    );
  }
}
