import { FontAwesome5 } from '@expo/vector-icons';

import { useContext } from 'react';
import { Pressable, StyleSheet } from 'react-native';
import { apiSignin, AuthContext, firebaseProviders } from '../api';
import SiteLogo from '../components/SiteLogo';

import { Background, Text, View } from '../components/Themed';
import { RootStackScreenProps } from '../types';


const styles = StyleSheet.create({
  container: {
    paddingTop: 100,

    flex: 1,
    alignItems: 'center',
    textAlign: 'center',
    //justifyContent: 'center',
  },

  item: {
    padding: 10,
  },

  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },

});

function SigninButtonGoogle() {

  function doLogin() {
    return apiSignin(firebaseProviders.google).then((result) => {
      //console.log('LOGIN RESULT:', result);
      // set global context
      //navigation.replace('Home');
    }).catch((error) => {
      // login failed, show error message
      //console.log('LOGIN ERROR:', error);
    });
  }

  return (
    <FontAwesome5.Button name="google" onPress={doLogin}>
      <Text style={{ fontWeight: 'bold', fontSize: 20 }}>Sign in with Google</Text>
    </FontAwesome5.Button>
  );
}

export default function SigninScreen() {
  // const { user } = useContext(AuthContext);

  return (
    <Background>
    <View style={styles.container}>
      <div style={{height:'30%', display:'flex', flexDirection:'column'}}>
        <SiteLogo />
      </div>

      <View style={styles.item}>
        <Text style={styles.title}>Sign in to InterGauge with your provider:</Text>
      </View>

      <View style={styles.item}>
        <SigninButtonGoogle />
      </View>
    </View>
    </Background>

  );
}

const styles_ = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: '#2e78b7',
  },
});

