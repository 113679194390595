import React from 'react';
import { View } from 'react-native';

interface SquaresGraphProps {
    liveData: Array<Array<number>>
}
 
interface SquaresGraphState {
    width: number
    height: number
    liveTargetData: Array<Array<number>>
}

const clone = (items) => items.map(item => Array.isArray(item) ? clone(item) : item);

function getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

class SquaresGraph extends React.Component<SquaresGraphProps, SquaresGraphState> {
    interval: number;
    state = {
      width: 0,
      height: 0,
      liveTargetData: this.props.liveData ?? [[0.0, 0.0, 0.0]]
    };

    onLayout = (event: any) => {
        const { width, height } = event.nativeEvent.layout;
        this.setState({ width, height });
    };
    
    componentDidUpdate(prevProps: SquaresGraphProps) {
        if (this.props.liveData !== prevProps.liveData) {
          this.setState({ liveTargetData: this.props.liveData });
        }
    }
    
    genSquares = (array) => {
        if(this.state.liveTargetData[0].reduce((a, b) => a + b, 0) === 0) {
            return <rect width={100} height={100} fill='#444444' />
        }

        array = [...array.slice(-1)]
        let arr = clone(array.slice(-1))[0]

        arr[1] += arr[2]
        arr[0] += arr[1]
        
        let n = arr[0]

        let height = this.state.height
        let width = this.state.width

        let x_dim = Math.sqrt(n*(this.state.width/this.state.height))
        let y_dim = x_dim*(this.state.height/this.state.width)

        let x_count = 100000
        let y_count = 100000

        if (height/width >= n)
        {
            y_count = n
            x_count = 1
        }
        else if (width/height >= n)
        {
            x_count = n
            y_count = 1
        }
        // Find the best ratio
        if(Math.floor(x_dim)*Math.floor(y_dim) >= n && Math.floor(x_dim)*Math.floor(y_dim) < x_count*y_count)
        {
            x_count = Math.floor(x_dim)
            y_count = Math.floor(y_dim)
        }
        if(Math.floor(x_dim)*Math.ceil(y_dim) >= n && Math.floor(x_dim)*Math.ceil(y_dim) < x_count*y_count)
        {
            x_count = Math.floor(x_dim)
            y_count = Math.ceil(y_dim)
        }
        if(Math.ceil(x_dim)*Math.floor(y_dim) >= n && Math.ceil(x_dim)*Math.floor(y_dim) < x_count*y_count)
        {
            x_count = Math.ceil(x_dim)
            y_count = Math.floor(y_dim)
        }
        if(Math.ceil(x_dim)*Math.ceil(y_dim) >= n && Math.ceil(x_dim)*Math.ceil(y_dim) < x_count*y_count)
        {
            x_count = Math.ceil(x_dim)
            y_count = Math.ceil(y_dim)
        }

        if(x_count >= 100000)
            return <line x1="0" y1="80" x2="100" y2="20" stroke="black" />

        let vertical_seperators = []
        for(let i = 0; i <= x_count; i++)
            vertical_seperators.push(width*i/x_count)
        let horizontal_seperators = []
        for(let i = 0; i <= y_count; i++)
        horizontal_seperators.push(height*i/y_count)


        let coords = []
        for(let i = 0; i < 3; i++)
        {
            let coord = [Math.floor(arr[i]/x_count)]
            coord.push(arr[i]-(coord[0]*x_count))
            coord.push(Math.ceil(coord[1]/x_count)+coord[0])
            coords.push(coord)
        }
        

        return ([
            <rect key={0} width={width} height={height*(coords[0][0]/y_count)} fill='#ff5959' />,
            <rect key={1} width={width*(coords[0][1]/x_count)} height={height*(coords[0][2]/y_count)} fill='#ff5959'/>,
            <rect key={2} width={width} height={height*(coords[1][0]/y_count)} fill='#ffff59' />,
            <rect key={3} width={width*(coords[1][1]/x_count)} height={height*(coords[1][2]/y_count)} fill='#ffff59'/>,
            <rect key={4} width={width} height={height*(coords[2][0]/y_count)} fill='#59ff59' />,
            <rect key={5} width={width*(coords[2][1]/x_count)} height={height*(coords[2][2]/y_count)} fill='#59ff59'/>,
            vertical_seperators.map(item => <line key={item} x1={item} x2={item} y1={0} y2={height} stroke='black' strokeOpacity="0.1" strokeWidth='1%'/>),
            horizontal_seperators.map(item => <line key={item} x1={0} x2={width} y1={item} y2={item} stroke='black' strokeOpacity="0.1" strokeWidth='1%'/>)
        ])
    }

    render() {
        return (
        <View onLayout={this.onLayout} style={{flex:1}}>
            <svg viewBox={"0 0 "+this.state.width.toString()+' '+this.state.height.toString()} style={{backgroundColor: "#444444", flex:1}}  preserveAspectRatio="none">
                {this.genSquares(this.state.liveTargetData)}
            </svg>
        </View>
        );
    }
}
 
export default SquaresGraph;