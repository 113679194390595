import { useState } from 'react';
import { Text, View } from '../components/Themed';
import { StyleSheet, TextInput, TouchableOpacity } from 'react-native';


export default function JoinRoomButton({ navigation }: { navigation: any }) {
    // keep track of the room ID
    const [roomid, setRoomid] = useState('');
    
    return (
      <View style={styles.container}>
        <TextInput style={styles.input} value={roomid} placeholder="Enter Room ID..." onChangeText={setRoomid}/>
  
        <TouchableOpacity style={styles.button} onPress={() => { navigation.navigate('Room', { roomid: roomid }) }}>
            <Text style={styles.buttonText}>JOIN</Text>
        </TouchableOpacity>
      </View>
  
    )
}

const styles = StyleSheet.create({
container: {
    flexDirection: 'row',
    justifyContent: 'center',
    height: 50,
    borderRadius: 10,
},
input: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    flex: 1,
    fontSize: 24,
    padding: 10,
    backgroundColor: '#fff',
},
button: {
    height: '100%', 
    justifyContent: 'center', 
    padding: 10,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    backgroundColor: '#5959ff'
},
buttonText:
{
    fontWeight: 'bold', 
    fontSize: 24
},
})