// App.tsx - entry point for InterGuage (intr.gg) app

import * as Linking from 'expo-linking';

import { SafeAreaProvider } from 'react-native-safe-area-context';

import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { DarkTheme, DefaultTheme, LinkingOptions, NavigationContainer } from '@react-navigation/native';

import React, { useContext, useEffect, useState } from 'react';

import { RootStackParamList } from './types';

import { AuthContext, AuthProvider } from './api';

import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';

import NotFoundScreen from './screens/NotFoundScreen';
import SigninScreen from './screens/SigninScreen';
import HomeScreen from './screens/HomeScreen';
import RoomScreen from './screens/RoomScreen';

import Loading from './components/Loading';

// URL linking configuration for this app
const LinkingConfiguration: LinkingOptions<RootStackParamList> = {
    prefixes: [Linking.createURL('/')],
    config: {
        screens: {
            // URL structured paths
            NotFound: '*', // 404
            Signin: 'signin',
            Home: '',
            Room: {
                path: 'room/:roomid',
            },
        },
    },
};

// root stack navigator, handles all screens
const Stack = createNativeStackNavigator();

// root navigator, handles all screens
function RootNavigator() {
    const user = useContext(AuthContext);
    if (!user) {
        return <SigninScreen />
    }

    //<Stack.Screen name="Signin" component={SigninScreen} />

    //<Stack.Screen name="Room" component={RoomScreen} options={({ route }) => ({ title: route.params.roomid })}/>
    //

    return (
        <Stack.Navigator>
            <Stack.Screen name="Home" component={HomeScreen} />
            <Stack.Screen name="Room" component={RoomScreen} options={({ route }) => ({ title: 'Room: ' + route.params.roomid })} />
            <Stack.Screen name="NotFound" component={NotFoundScreen} options={{
                title: '404: Not Found!',
            }} />
        </Stack.Navigator>
    )
}

export default function App() {
    // initial loading hooks
    const hasCachedResources = useCachedResources();
    const colorScheme = useColorScheme();

    // select which theme to use
    const theme = colorScheme === 'dark' ? DarkTheme : DefaultTheme;

    // provide authentication and navigation, then show the root navigation (which handles different screens)
    return (
        <SafeAreaProvider>
            <AuthProvider>
                <NavigationContainer
                    linking={LinkingConfiguration}
                    theme={theme}>
                    <RootNavigator />
                </NavigationContainer>
            </AuthProvider>
        </SafeAreaProvider >
    )
}


/*

// whether we are loading or not
//const [isLoading, setIsLoading] = useState(true);

// authentication state
//const [user, setUser] = useState<User | null>(null);

if (!hasCachedResources) {
    // still loading, show splash screen
    return (
        <SafeAreaProvider>
            <Loading message="Loading Intergauge..." />
        </SafeAreaProvider>
    )
}

if (!user) {
    // not logged in, so only show sign in screen
    return (
        <SafeAreaProvider>
            <SigninScreen />
        </SafeAreaProvider>
    )
}

// otherwise, provide authentication and navigation, then show the root navigation (which handles different screens)
return (
    <SafeAreaProvider>
        <AuthProvider>
            <NavigationContainer
                linking={LinkingConfiguration}
                theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
                <RootNavigator />
            </NavigationContainer>
        </AuthProvider>
    </SafeAreaProvider >
)
*/
